import styled from 'styled-components'

interface IHeaderProps {
  backgroundHeader: string
  backgroundPlayer: string
}

interface IFlixBuyProps {
  backgroundTv: string
}

export const Container = styled.div`
  position: relative;
  padding-bottom: 40px;

  .top {
    width: 100%;
    position: sticky;
    top: 0;
    grid-column: span 2;
    padding: 5px 20px;
    background: var(--purple-100-rgba);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    box-shadow: var(--black-rgba) 1px 0 9px 0;

    @media (max-width: 480px) {
      img {
        max-width: 130px;
      }
    }

    a.signin {
      color: var(--white);
      font-weight: 600;
      backdrop-filter: blur(50px);
      padding: 10px 20px;
      border: 2px solid var(--purple);
      border-radius: 20px;
      box-shadow: var(--black-rgba) 1px 2px 3px 0;

      &:hover {
        transition: ease-in 0.4s;
        background-color: var(--purple-100);
        border: 2px solid var(--purple-100);
      }
    }
  }
`

export const Header = styled.header<IHeaderProps>`
   {
    position: relative;
    background-image: url('${({ backgroundHeader }) => backgroundHeader}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: grid;
    grid-template-rows: auto 1fr;
    justify-items: center;

    padding-bottom: 200px;
    margin-bottom: 100px;

    .last-items {
      position: absolute;
      bottom: -90px;
      z-index: 1;
      //height: 260px;
      width: 100%;

      header {
        margin-bottom: 1rem;
        padding: 0 10px;

        > h1 {
          color: var(--white);
          font-size: 1.4rem;
          text-shadow: var(--black) 1px 1px 2px;
        }
      }
    }

    .content {
      grid-column: span 2;
      width: 100%;
      max-width: 1280px;
      padding: 40px 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      grid-gap: 2rem;
      justify-content: space-between;
      align-items: center;
      align-self: center;

      @media (max-width: 1030px) {
        grid-template-columns: 1fr;
        justify-items: center;
      }

      .sign {
        color: var(--white);
        @media (max-width: 1030px) {
          h1 {
            text-align: center;

            > span {
              display: initial;
            }
          }

          p {
            text-align: center;
            font-size: 1.2rem;
          }
        }

        h1 {
          font-size: 2.2rem;
          text-shadow: var(--black) 1px 1px 2px;

          > span {
            display: block;
          }
        }

        p {
          margin: 10px 0;
          text-shadow: var(--black) 0 0 2px;
          font-weight: 600;
        }

        div {
          display: flex;
          align-items: center;

          @media (max-width: 1030px) {
            justify-content: flex-end;
            margin-top: 20px;
          }

          strong {
            text-shadow: var(--black) 0 0 2px;
            font-size: 1.2rem;
            margin-right: 20px;

            span {
              font-size: 2.6rem;
            }
          }
        }
      }

      .logo-vertical {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        text-align: center;
        border: 10px solid var(--purple-100-rgba);
        background-color: var(--purple-300-rgba);
        backdrop-filter: blur(10px);
        border-radius: 6px;
        box-shadow: var(--black-rgba) 3px 1px 4px 0;

        .vd-crop {
          display: grid;
          align-items: center;
          overflow: hidden;
          max-height: 667px;

          .vd-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 0;
            height: 0;
            overflow: hidden;
            //z-index: -1;

            video,
            iframe,
            object,
            embed {
              border: none;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
`

export const HighLights = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;

  .fix-top {
    top: -90px;
    margin-bottom: -60px;
  }

  .highlights {
    width: 100%;
    position: relative;
    height: 340px;

    article {
      position: absolute;
      z-index: 1;

      width: 100%;
    }
  }

  .high {
    width: 100%;
    position: relative;
    height: 400px;

    article {
      position: absolute;

      z-index: 1;
      width: 100%;

      .card {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
`

export const PricePlans = styled.section`
  display: grid;

  justify-content: center;

  margin: 80px auto;

  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  grid-column-gap: 1rem;
  grid-row-gap: 3rem;
  padding: 0 20px;
`

interface IPlanProps {
  color?: string
  backgroundColor?: string
}

export const Plan = styled.article<IPlanProps>`
  width: 100%;
  background-color: ${({ backgroundColor }) => `var(${backgroundColor})`};
  border: ${({ color }) => `var(${color})`} 1px solid;
  color: ${({ color }) => `var(${color})`};
  padding: 30px 0;
  border-radius: 10px;
  display: grid;
  grid-row-gap: 1rem;
  text-shadow: var(--black) 1px 1px 2px;
  position: relative;

  @media (min-width: 720px) {
    :nth-child(2) {
      transform: scale(1.15);
      z-index: 1;
    }
  }

  header {
    margin-bottom: 1rem;

    h1 {
      text-align: center;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }

  div {
    padding: 20px 50px;
    background-color: var(--black-rgba);
    text-align: center;

    strong {
      font-size: 1.4rem;
      font-weight: 700;
    }

    p {
      display: block;
    }
  }

  p {
    padding: 0 50px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      box-shadow: var(--black) 0 0 2px;
      border-radius: 15px;
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        20% {
          transform: rotate(360deg);
        }
        40% {
          transform: rotateX(180deg);
        }
        60% {
          transform: rotateX(180deg);
        }
        80% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1);
        }
      }

      transform: rotate(0deg);
      animation: rotate 2s ease-in-out infinite;
    }

    span.destaq {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 0.9rem;
      color: var(--gold);
      text-shadow: var(--black) 0 0 3px;
    }
  }

  a {
    position: relative;
    justify-self: center;
    padding: 15px 40px;
    text-transform: uppercase;
    color: var(--white);
    border: 2px solid var(--pink);
    outline: none;
    background-color: var(--purple-300);
    bottom: -55px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px var(--black);
    font-weight: 900;

    transition: 0.6s;
    overflow: hidden;

    display: flex;
    place-items: center;
    place-content: center;

    :hover {
      box-shadow: 0 0 10px var(--pink), 0 0 15px var(--pink-100),
        0 0 25px var(--pink-100);
      background-color: var(--pink);
      transition-delay: 1s;
      border: 2px solid var(--pink);

      span:nth-child(1) {
        left: 100%;
        transition: 1s;
      }

      span:nth-child(2) {
        top: 100%;
        transition: 1s;
        transition-delay: 0.25s;
      }

      span:nth-child(3) {
        right: 100%;
        transition: 1s;
        transition-delay: 0.5s;
      }

      span:nth-child(4) {
        bottom: 100%;
        transition: 1s;
        transition-delay: 0.75s;
      }
    }

    span {
      position: absolute;
      display: block;
      box-shadow: 0 0 10px var(--pink), 0 0 15px var(--pink-100),
        0 0 10px var(--pink-100);

      :nth-child(1) {
        width: 100%;
        height: 1px;
        top: 0;
        left: -100%;
        background-image: linear-gradient(90deg, transparent, var(--pink));
      }

      :nth-child(2) {
        width: 1px;
        height: 100%;
        right: 0;
        top: -100%;

        background-image: linear-gradient(180deg, transparent, var(--pink));
      }

      :nth-child(3) {
        width: 100%;
        height: 1px;
        bottom: 0;
        right: -100%;

        background-image: linear-gradient(270deg, transparent, var(--pink));
      }

      :nth-child(4) {
        width: 1px;
        height: 100%;
        left: 0;
        bottom: -100%;

        background-image: linear-gradient(360deg, transparent, var(--pink));
      }
    }
  }
`

export const SignWithText = styled.section`
  position: relative;
  width: 100%;
  max-width: 1024px;
  padding: 20px;

  display: grid;
  justify-items: center;
  margin: 0 auto;

  h2 {
    color: var(--white);
    text-align: center;
  }

  div {
    margin-top: 20px;
    display: flex;
    align-items: center;

    p {
      font-weight: 700;
      color: var(--white);

      strong {
        color: var(--pink);
        font-size: 1.2rem;
        margin-right: 20px;

        span {
          color: var(--pink);
          font-size: 2.6rem;
        }
      }
    }
  }
`

export const ViewAnyWhere = styled.section`
  width: 100%;
  background-color: var(--purple-100);
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }

  > div {
    img {
      width: 100%;
      max-width: 625px;
      height: auto;
    }
  }

  article {
    color: var(--white);
    width: 100%;
    max-width: 530px;
    padding: 0 20px;

    header {
      margin-bottom: 20px;

      h1 {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.2rem;
    }

    div {
      margin: 20px 0;
      display: flex;
      grid-column-gap: 1rem;
    }
  }
`

export const WhyBuy = styled.section`
  background-color: var(--purple-300);
  padding: 40px 20px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  h2 {
    color: var(--white);
    font-size: 2rem;
    text-align: center;
    font-weight: 400;

    strong {
      display: block;
      font-weight: 900;
    }
  }

  .icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
    justify-content: center;
    grid-gap: 2rem;
    margin: 40px auto;
    position: relative;

    div {
      text-align: center;
      @keyframes float {
        0% {
          transform: translatey(0px);
        }
        50% {
          transform: translatey(-10px);
        }
        100% {
          transform: translatey(0px);
        }
      }

      transform: translatey(0px);
      animation: float 3s ease-in-out infinite;

      img {
        @keyframes bright {
          0% {
            box-shadow: var(--purple-200) 0 0 9px 3px;
          }
          50% {
            box-shadow: var(--purple-100) 0 0 9px 3px;
          }
          100% {
            box-shadow: var(--purple) 0 0 9px 3x;
          }
        }
        animation: bright 3s alternate infinite;
        width: 100%;
        max-width: 130px;
        border-radius: 65px;
        border: 2px solid var(--purple-200);
      }

      h3 {
        margin: 10px 0;
        color: var(--white);
        font-weight: 400;

        span {
          color: var(--pink);
          display: block;
        }
      }

      transition: 0.2s ease-out;

      &:hover {
        animation: none;
        transition: 0.2s ease-in;
        transform: scale(1.2);
      }
    }
  }

  .why {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;

    header {
      margin-bottom: 20px;

      h1 {
        font-size: 2rem;
        color: var(--white);
        text-align: center;
      }
    }

    ul {
      background-color: var(--white-300);
      border-radius: 4px;
      padding: 30px 40px;
      margin: 0;
      list-style: none;

      @media (max-width: 480px) {
        padding: 15px;
      }

      li {
        display: flex;
        align-items: center;
        color: var(--purple-100);
        font-size: 1.4rem;
        margin: 10px 0;

        strong {
          font-weight: 900;
        }

        img {
          width: 40px;
          margin-right: 20px;
        }
      }
    }
  }

  .faq {
    display: grid;
    grid-template-columns: repeat(auto-fit, 290px);
    grid-gap: 1rem;
    margin: 60px auto 0 auto;
    justify-content: center;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }

    > article {
      background-color: var(--white-300);
      border-radius: 4px;
      padding: 30px 20px;
      text-align: center;

      img {
        margin: 0 auto;
      }

      h2 {
        margin: 10px auto;
        text-align: center;
        color: var(--purple-300);
        font-size: 1.2rem;
        font-weight: 700;
        width: 210px;
        @media (max-width: 480px) {
          width: 100%;
        }
      }

      p {
        color: var(--purple);
        font-size: 1.2rem;
      }
    }
  }
`

export const FlixBuy = styled.div<IFlixBuyProps>`
  background-image: url('${({ backgroundTv }) => backgroundTv}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 1080px;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 450px));
  padding: 50px 80px;
  margin: 70px auto;

  border: 10px solid var(--purple-100-rgba);
  background-color: var(--purple-300-rgba);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  box-shadow: var(--black-rgba) 3px 1px 4px 0;

  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 40px 20px;

    img {
      margin: 0 auto;
    }

    article {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      margin: 0 auto;
    }
  }

  img {
    width: 100%;
    max-width: 200px;
  }

  article {
    width: 100%;
    max-width: 450px;

    header {
      margin-bottom: 20px;

      h1 {
        font-size: 2rem;
        color: var(--white);
      }
    }

    div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      h2 {
        font-weight: 700;
        font-size: 1rem;

        span {
          font-size: 2.6rem;
        }
      }
    }
  }
`

export const SiginNow = styled.section`
  width: 100%;
  max-width: 1080px;
  margin: 50px auto;
  display: grid;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;

  img {
    margin: 0 auto;
    max-width: 220px;
    grid-column: span 2;
  }

  header {
    h1 {
      color: var(--white);

      span {
        font-size: 3rem;
        color: var(--pink);
      }
    }
  }
`

export const VideoFree = styled.div`
  width: 100%;
  max-width: 1280px;
  display: grid;
  color: var(--white);
  text-align: center;
  background: var(--black-rgba);
  padding: 20px;
  margin: 0 auto;
  border: 10px solid var(--purple-100-rgba);
  background-color: var(--purple-300-rgba);
  backdrop-filter: blur(10px);
  border-radius: 6px;
  box-shadow: var(--black-rgba) 3px 1px 4px 0;

  @media (max-width: 720px) {
    padding: 10px;
    background: none;
    box-shadow: none;
    border-radius: 0;
  }

  h2 {
    color: var(--pink);
    text-transform: uppercase;
    background: var(--black-rgba);
    padding: 10px;
    border-radius: 10px;
    font-size: 2rem;
    font-weight: 900;
  }

  > p {
    font-size: 1.2rem;
    margin: 10px 0;

    svg {
      margin-right: 5px;
    }
  }

  > h3 {
    text-transform: uppercase;
    text-shadow: var(--black-rgba) 1px 1px 3px;
    font-size: 1.6rem;
    margin-top: 20px;
  }

  > small {
    text-align: left;
    border: 2px solid var(--purple);
    margin-top: 10px;
    padding: 20px;
    font-size: 1rem;

    a {
      padding: 10px 20px;
      border-radius: 10px;
      margin-top: 10px;
      display: inline-block;
      font-size: 0.8rem;
      text-transform: uppercase;
      background: var(--black-rgba);

      &:hover {
        background: var(--black);
      }
    }
  }

  .vd-crop {
    margin-top: 20px;
    display: grid;
    align-items: center;
    overflow: hidden;
    //max-height: 667px;
    //border-radius: 10px;

    .vd-container {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 0;
      height: 0;
      overflow: hidden;
      //z-index: -1;

      video,
      iframe,
      object,
      embed {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`
