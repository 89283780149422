import React, { useContext, useEffect } from 'react'
import { Container } from './styles'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

interface IVideoCardProps {
  title: string
  video_id: number
  itemId: string
  cover: string
  large: boolean
  cover_origin: string | null
}

export function VideoCardLastDashboard({
  title,
  video_id,
  itemId,
  cover_origin,
  cover,
  large
}: IVideoCardProps) {
  const visibility = useContext(VisibilityContext)

  useEffect(() => {
    visibility.isItemVisible(itemId)
  }, [itemId, visibility])

  return (
    <Container tabIndex={0} backgroundImg={cover} largeTemplate={large}>
        <div className="card">{!cover_origin && <h2>{title}</h2>}</div>
    </Container>
  )
}
