import styled from "styled-components";

export const Container = styled.a`
  background-color: var(--pink);
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: var(--white);

  &:hover {
    transition: ease .2s;
    transform: scale(1.1);
    box-shadow: var(--black) 0 0 3px 0;
  }
`;
