import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 20px 40px;
  }

  .react-horizontal-scrolling-menu--wrapper {
    position: relative;
  }

  header {
    margin-bottom: 1rem;
    padding: 0 10px;

    > h1 {
      color: var(--white);
      font-size: 1.4rem;
      text-shadow: var(--black) 1px 1px 2px;
    }
  }

  :hover {
    button {
      transition: opacity 0.2s ease-in;
      opacity: 1;
    }
  }
`
