import React, { ContextType, WheelEvent } from 'react'
import { Container } from './styles'
import {
  ScrollMenu,
  slidingWindow,
  VisibilityContext,
  getItemsPos
} from 'react-horizontal-scrolling-menu'
import { Left, Right } from './Arrows'
import { VideoCard } from '../VideoCard'
import useDrag from '../../Context/useDrag'

interface IVideoProps {
  thumb: string
  title: string
  author: string
  video_url: string
}

interface IHorizontalScrollProps {
  title: string
  fetchItems?: () => void
  items: IVideoProps[]
  newItemsLimit?: number
  subtitle?: boolean
  largeCard?: boolean
}

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>

export function HorizontalScroll({
  title,
  items,
  fetchItems,
  newItemsLimit = 20,
  subtitle = false,
  largeCard = false
}: IHorizontalScrollProps) {
  const { dragStart, dragStop, dragMove } = useDrag()

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  function onWheel(
    { getItemById, items, visibleItems, scrollToItem }: scrollVisibilityApiType,
    ev: WheelEvent
  ): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

    if (isThouchpad) {
      ev.stopPropagation()
      return
    }

    if (ev.deltaY < 0) {
      // NOTE: for center items
      const nextGroupItems = slidingWindow(
        items.toItemsKeys(),
        visibleItems
      ).next()
      const { center } = getItemsPos(nextGroupItems)
      scrollToItem(getItemById(center), 'smooth', 'center')
    } else if (ev.deltaY > 0) {
      const prevGroupItems = slidingWindow(
        items.toItemsKeys(),
        visibleItems
      ).prev()
      const { center } = getItemsPos(prevGroupItems)
      scrollToItem(getItemById(center), 'smooth', 'center')
    }
  }

  return (
    <Container>
      <header>{!subtitle && <h1>{title}</h1>}</header>

      <div>
        {subtitle && <h2>{title}</h2>}
        <ScrollMenu
          LeftArrow={Left}
          RightArrow={<Right limit={newItemsLimit} pushNewItems={fetchItems} />}
          onWheel={() => onWheel}
          onMouseDown={() => dragStart}
          onMouseUp={({
              getItemById,
              scrollToItem,
              visibleItems
            }: scrollVisibilityApiType) =>
            () => {
              dragStop()
              const { center } = getItemsPos(visibleItems)
              scrollToItem(getItemById(center), 'smooth', 'center')
            }}
          // @ts-ignore
          onMouseMove={handleDrag}
          options={{
            throttle: 0,
            ratio: 0.9,
            rootMargin: '15px',
            threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
          }}
        >
          {items.map((item, id) => (
            <VideoCard
              large={largeCard}
              itemId={String(id)} // NOTE: itemId is required for track items
              card={item}
              key={id}
            />
          ))}
        </ScrollMenu>
      </div>
    </Container>
  )
}
