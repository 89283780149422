import styled from 'styled-components'

export const Container = styled.footer`
  background-color: var(--purple-100);
  color: var(--white);
  width: 100%;
  box-shadow: var(--black-rgba) -1px -1px 3px 0;

  .container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    grid-template-areas: 'about support';

    padding: 40px 20px;
    grid-column-gap: 2rem;

    @media (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-template-areas: 
      'support'
      'about'
    ;
      grid-row-gap: 2rem;
    }

    article {
      width: 100%;

      header {
        margin-bottom: 20px;

        h1 {
          text-align: center;
        }
      }

      p {
        margin-bottom: 10px;;
      }

      div {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30px, 40px));
        justify-items: center;

        a {
          transition: ease-in .2s;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;

          svg {
            font-size: 1.3rem;
          }

          &:hover {
            transition: ease-out .2s;
            color: var(--pink);
            transform: scale(1.2);
            box-shadow: var(--black-rgba) 0 0 2px 0;
            background-color: var(--purple-300);
          }
        }
      }
    }

    .about {
      grid-area: about;
    }

    .support {
      grid-area: support;

      p {
        text-align: center;

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  .bottom {
    color: var(--purple);
    background-color: var(--purple-300);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 900;
    padding: 10px 0;

    img {
      margin: 0 3px;
    }
  }

`
