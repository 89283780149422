import styled from 'styled-components'

interface IPropsVideoCard {
  largeTemplate: boolean
}

export const Container = styled.div<IPropsVideoCard>`
  margin-right: 15px;
  outline: none;

  .card {
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--black);
    display: grid;
    box-shadow: var(--black) 1px 1px 2px 0;
    cursor: pointer;
    transition: ease-out 0.2s;

    width: ${({ largeTemplate }) => (largeTemplate ? 430 : 360)}px;
    height: ${({ largeTemplate }) => (largeTemplate ? 300 : 230)}px;
    position: relative;

    @media (max-width: 480px) {
      width: ${({ largeTemplate }) => (largeTemplate ? 390 : 360)}px;
      height: ${({ largeTemplate }) => (largeTemplate ? 300 : 230)}px;
    }

    .videos-int {
      background-color: var(--black);
      display: none;
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      height: 0;

      video,
      iframe {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      transition: ease-in 0.2s;
      transform: scale(1.2);

      @media (max-width: 480px) {
        transform: none;
        margin-bottom: 20px;
      }
      z-index: 1;

      .videos-int {
        display: initial;
      }

      .bg-img {
        display: none;
      }

      .controls {
        transition: ease-in 0.2s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column-gap: 1rem;
        box-shadow: var(--black) 1px 1px 2px 0;
      }
    }

    .controls {
      position: absolute;
      z-index: 2;
      bottom: 0;
      background-color: var(--black-rgba-300);
      display: none;
      width: 100%;
      padding: 15px 10px;

      h2 {
        color: var(--white);
        font-size: 0.8rem;
        font-weight: 400;
      }

      a {
        font-size: 0.75rem;
      }
    }
  }
`
