import styled from 'styled-components'

interface Props {
  background: string
  cardHeight: number
}

export const Container = styled.div<Props>`
  background-image: url('${({ background }) => background}');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  .gradient {
    padding-top: ${({cardHeight}) => cardHeight}px;
    background: var(--white);
    background: linear-gradient(
      180deg,
      var(--black-100-rgba) 0%,
      var(--black-200-rgba) 90%
    );
    
    @media (max-width: 480px) {
      padding-top: ${({cardHeight}) => cardHeight}px;
    }
    
  }

  .pre-control {
    padding: 15px 20px;
    color: var(--white);
    position: absolute;
    bottom: 0;
    
    .tag {
      background-color: var(--pink);
      border-radius: 20px;
      padding: 10px 30px;
      font-size: 0.85rem;
      text-transform: uppercase;
      display: inline-flex;
      margin-bottom: 10px;
    }

      h2 {
      font-size: 1rem;
      text-shadow: var(--black) 1px 1px 3px;
    }
    p {
      margin-top: 5px;
      font-size: .85rem;
    }
  }
`
