import React from 'react'
import { Container } from './styles'

interface ISiginButtonProps {
  href: string
  title: string
}

export function SiginButton({ href, title }: ISiginButtonProps) {
  return (
    <Container href={href} title={title} rel={'noreferrer'}>
      {title}
    </Container>
  )
}
