import React, { MouseEvent, useContext, useEffect } from 'react'
import { VisibilityContext } from 'react-horizontal-scrolling-menu'

import { CardImg } from '../CardImg'

import { SiginButton } from '../SiginButton'
import { Container } from './styles'

interface IVideoCardProps {
  itemId: string
  large: boolean
  card: any
}

export function VideoCard({ itemId, large, card }: IVideoCardProps) {
  const visibility = useContext(VisibilityContext)

  async function handleStartVideo(event: MouseEvent<HTMLVideoElement>) {
    await event.currentTarget.play()
  }

  function handleStopVideo(event: MouseEvent<HTMLVideoElement>) {
    event.currentTarget.pause()
  }

  useEffect(() => {
    JSON.stringify(!!visibility.isItemVisible(itemId))
  }, [itemId, visibility])

  return (
    <Container tabIndex={0} largeTemplate={large}>
      <div className="card" key={card.id}>
        <div className="videos-int">
          <video
            playsInline
            loop
            controls={false}
            preload={'metadata'}
            muted
            onMouseLeave={handleStopVideo}
            onMouseEnter={handleStartVideo}
            src={card.video_url}
          />
        </div>
        <CardImg
          classCustom={'bg-img'}
          backgroundImg={card.thumb}
          title={card.title}
          author={card.author}
          cardHeight={large ? 300 : 230}
        />
        <div className="controls">
          <h2>{card.title}</h2>
          <SiginButton href={card.link} title={'Assinar'} />
        </div>
      </div>
    </Container>
  )
}
