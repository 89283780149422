import React, { useEffect, useState } from 'react'

import LogoDankiClubWebp from '../../assets/images/logo-dankiclub-horizontal/logodankiclub-horizontal.webp'
import BgTechHeaderJPEG from '../../assets/images/cover-profile.jpeg'
import LogoDankiClubVerticalWebp from '../../assets/images/danki-club-logo-vertical/identidade-logopng@2x.webp'
import BgPlayerWebp from '../../assets/images/banner-dankiclub-background/banner-dankiclub-2-background.webp'
import BgSectionAnyWhere from '../../assets/images/imagem-tv/imagem-tv.png'
import GooglePlayImg from '../../assets/images/google-play/google-play.png'
import AppleStore from '../../assets/images/app-store/app-store.png'
import DevSvg from '../../assets/images/icons/dev.svg'
import MobileSvg from '../../assets/images/icons/mobile.svg'
import GamesSvg from '../../assets/images/icons/games.svg'
import CryptoSvg from '../../assets/images/icons/crypto.svg'
import BrainSvg from '../../assets/images/icons/brain.svg'
import BgFlixWebp from '../../assets/images/banner-dankiclub-background/banner-dankiclub-2-background@2x.webp'
import CheckedSvg from '../../assets/images/icons/checked.svg'
import FaqSvg from '../../assets/images/icons/faq.svg'

import thumb_1 from '../../assets/images/thumbs/efeito-vidro.jpeg'
import efeito_vidro_mp4 from '../../assets/videos/efeito-vidro-preview-end.mp4'

import thumb_2 from '../../assets/images/thumbs/cutom-scrollbar.jpeg'
import custom_scrollbar from '../../assets/videos/custom-scrollbar-preview-end.mp4'

import thumb_3 from '../../assets/images/thumbs/custom-input-checkbox.jpeg'
import custom_checkbox from '../../assets/videos/custom-checkbox-preview-end.mp4'

import thumb_4 from '../../assets/images/thumbs/button-effect.jpeg'
import button_effect from '../../assets/videos/button-effect-preview-end.mp4'

import thumb_5 from '../../assets/images/thumbs/games-em-3d.jpeg'
import games_mp4 from '../../assets/videos/games-preview-end.mp4'

import thumb_6 from '../../assets/images/thumbs/web-3.jpeg'
import web_3 from '../../assets/videos/web-3-preview-end.mp4'

import thumb_7 from '../../assets/images/thumbs/facebook-ads.jpeg'
import anuncio_facebook from '../../assets/videos/anuncio-facebook-preview-end.mp4'

import thumb_8 from '../../assets/images/thumbs/thumb-8.jpg'
import analisando_blockchain from '../../assets/videos/analisando-blockchain-para-oportunidades-de-cripto-end-preview.mp4'

import thumb_9 from '../../assets/images/thumbs/thumb-9.jpg'
import criando_moeda from '../../assets/videos/criando-sua-propria-criptomoeda-em-10-minutos-preview-end.mp4'

import thumb_10 from '../../assets/images/thumbs/thumb-10.jpg'
import financas from '../../assets/videos/introducao-a-financas-em-10-minutos-preview-end.mp4'

import thumb_11 from '../../assets/images/thumbs/thumb-11.jpg'
import bootstrap from '../../assets/videos/como-criar-um-site-com-bootstrap-preview-end.mp4'

import DankiTokenSvg from '../../assets/images/danki-token.svg'
import { IVideoDashboardProps } from '../../Interfaces/VideoProps'
import {
  Container,
  FlixBuy,
  Header,
  HighLights,
  Plan,
  PricePlans,
  SiginNow,
  SignWithText,
  ViewAnyWhere,
  WhyBuy,
  VideoFree
} from './styles'

import Vimeo from '@u-wave/react-vimeo'
import { SiginButton } from '../../components/SiginButton'
import { HorizontalScroll } from '../../components/HorizontalScroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronCircleRight,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import { api } from '../../services/api'
import { HorizontalScrollLastDashboard } from '../../components/HorizontalScrollLastDashboard'
import {ModalDefault} from "../../components/ModalDefault";

interface IVideoProps {
  title: string
  video_url: string
  thumb: string
  enabledAt?: number
  author: string
  link?: string
}

export interface IProductProps {
  id: number
  title?: string
  name: string
  granted: {
    id: number
    name: string
    icon?: string
  }[]
  price: string
  installments: string
  link: string
  color: string
  backgroundColor: string
}

export interface IVideoFreeProps {
  id: number
  title: string
  cover: string
  slug: string
  content: string
  external_id: number
  author: string
  cover_author: string
  cover_url: string
}

const { REACT_APP_TOKEN_REQUEST } = process.env

export function Home() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [videoFree, setVideoFree] = useState<IVideoFreeProps>(
    {} as IVideoFreeProps
  )
  const [videoList, setVideoList] = useState<IVideoDashboardProps>(
    {} as IVideoDashboardProps
  )
  const [pageVideoList, setPageVideoList] = useState(1)

  const bestLink = 'https://pay.hotmart.com/N68542651N?off=0di1tvyq'

  const highlights: IVideoProps[] = [
    {
      title: 'Como criar um site do absoluto zero com Bootstrap',
      video_url: bootstrap,
      thumb: thumb_11,
      author: 'Rafael Pacífico',
      link: bestLink
    },
    {
      title: 'Introdução a Finanças em 10 minutos',
      video_url: financas,
      thumb: thumb_10,
      author: 'Laura Pacheco',
      link: bestLink
    },
    {
      title: 'Analisando Blockchain para oportunidades de cripto',
      video_url: analisando_blockchain,
      thumb: thumb_8,
      author: 'Gui Grillo',
      link: bestLink
    },
    {
      title: 'Criando sua própria criptomoeda em 10 minutos',
      video_url: criando_moeda,
      thumb: thumb_9,
      author: 'Gui Grillo',
      link: bestLink
    },
    {
      title: 'Desenvolvendo Jogos 3D em 2022',
      video_url: games_mp4,
      thumb: thumb_5,
      author: 'Gui Grillo',
      link: bestLink
    },
    {
      title: 'Efeito Vidro',
      video_url: efeito_vidro_mp4,
      thumb: thumb_1,
      author: 'Rafael Pacífico',
      link: bestLink
    },
    {
      title: 'Criando uma carteira de cripto com Javascript e Web3',
      video_url: web_3,
      thumb: thumb_6,
      enabledAt: 2,
      author: 'Gui Grillo',
      link: bestLink
    },
    {
      title: 'Efeito avançado em botão com HTML e CSS',
      video_url: button_effect,
      thumb: thumb_4,
      author: 'Rafael Pacífico',
      link: bestLink
    },
    {
      title: 'Criando Anuncios com Facebook',
      video_url: anuncio_facebook,
      thumb: thumb_7,
      enabledAt: 23,
      author: 'Gui Grillo',
      link: bestLink
    },
    {
      title: 'Scrollbar customizada',
      video_url: custom_scrollbar,
      thumb: thumb_2,
      author: 'Rafael Pacífico',
      link: bestLink
    },
    {
      title: 'Input Checkbox customizado',
      video_url: custom_checkbox,
      thumb: thumb_3,
      author: 'Rafael Pacífico',
      link: bestLink
    }
  ]

  const faqs = [
    {
      id: 1,
      title: 'Já sou aluno de outro curso, tenho acesso?',
      text: 'Ainda não. Este é um conteúdo exclusivo que você paga e consome sob sua demanda.'
    },
    {
      id: 2,
      title: 'Comprando eu tenho acesso a outros cursos?',
      text: 'A assinatura não se trata de um curso, mas sim VARIOS CONTEÚDOS diversos para você que quer começar ou aperfeiçoar sua vida profissional.'
    },
    {
      id: 3,
      title: 'Terei certificado ao final da conclusão da aula?',
      text: 'Assim como na Netflix, o conteúdo disponível no Danki Club não dá acesso a certificados, e sim a conhecimentos IMENSURÁVEIS para sua vida.'
    },
    {
      id: 4,
      title: 'Tenho desconto por já ser aluno?',
      text: 'Aproveite nossa promoção de lançamento e assine por menos de R$50,00.'
    }
  ]

  const products: IProductProps[] = [
    {
      id: 1,
      // title: 'Junior Black November',
      name: 'Junior',
      granted: [
        { id: 1, name: 'Acesso por 1 mês' },
        { id: 2, name: 'Reembolso garantido' },
        { id: 3, name: 'Acesso imediato da plataforma' },
        { id: 4, name: 'Acesso a todos os vídeos' },
        { id: 5, name: 'Download de todos os materiais das aulas' },
        { id: 6, name: 'Comunidade exclusiva' },
        { id: 7, name: 'Suporte VIP' }
      ],
      // price: '19,90',
      // installments: 'Menos de R$ 0,65 por dia',
      // link: 'https://pay.hotmart.com/N68542651N?off=5lpj64u9&checkoutMode=10',
      price: '24,87',
      installments: 'Menos de R$ 0,82 por dia',
      link: 'https://pay.hotmart.com/N68542651N?off=0di1tvyq&checkoutMode=10',
      color: '--white',
      backgroundColor: '--purple-200'
    },
    {
      id: 2,
      name: 'Sênior',
      granted: [
        { id: 1, name: 'Acesso por 12 meses' },
        { id: 2, name: 'Reembolso garantido' },
        { id: 3, name: 'Acesso imediato da plataforma' },
        { id: 4, name: 'Acesso a todos os vídeos' },
        { id: 5, name: 'Download de todos os materiais das aulas' },
        { id: 6, name: 'Comunidade exclusiva' },
        { id: 7, name: 'Suporte VIP' },
        { id: 8, name: 'Encomenda de aulas' },
        // {
        //   id: 9,
        //   name: 'Ganhe Danki Tokens ao adquirir esse plano',
        //   icon: DankiTokenSvg
        // }
      ],
      price: '239,87',
      installments: 'Em até 12x de R$ 23,37',
      link: 'https://pay.hotmart.com/N68542651N?off=5sbczd68&bid=1659484511494&checkoutMode=10',
      color: '--white',
      backgroundColor: '--pink'
    },
    {
      id: 3,
      name: 'Pleno',
      granted: [
        { id: 1, name: 'Acesso por 6 meses' },
        { id: 2, name: 'Reembolso garantido' },
        { id: 3, name: 'Acesso imediato da plataforma' },
        { id: 4, name: 'Acesso a todos os vídeos' },
        { id: 5, name: 'Download de todos os materiais das aulas' },
        { id: 6, name: 'Comunidade exclusiva' },
        { id: 7, name: 'Suporte VIP' },
        { id: 8, name: 'Encomenda de aulas' }
      ],
      price: '134,87',
      installments: 'Em até 6x de R$ 24,48',
      link: 'https://pay.hotmart.com/N68542651N?off=sb83sr7c&bid=1659471489282&checkoutMode=10',
      color: '--white',
      backgroundColor: '--purple-200'
    }
  ]

  const product = products.find((item) => item.id === 1)

  async function handleGetVideoList() {
    try {
      const { data } = await api.get(
        `/lp/videos?page=${pageVideoList}&per_page=10`,
        {
          headers: {
            Authorization: String(REACT_APP_TOKEN_REQUEST)
          }
        }
      )
      setVideoList(data)
    } catch (e) {
      console.warn('manutenção')
    }
  }

  async function pushNewItemsVideoList() {
    try {
      if (videoList.data.length >= 30) {
        return false
      }
      const pagePlus = pageVideoList + 1
      setPageVideoList(pagePlus)
      const { data } = await api.get(
        `/lp/videos?page=${pagePlus}&per_page=10`,
        {
          headers: {
            Authorization: String(REACT_APP_TOKEN_REQUEST)
          }
        }
      )
      const { data: videos } = data
      const newItems = videoList.data
      setVideoList({
        ...data,
        data: newItems.concat(videos)
      })
    } catch (e) {
      console.warn('manutenção')
    }
  }

  useEffect(() => {
    async function loadVideoFree() {
      const { data } = await api.get(`/lp/video-free`, {
        headers: {
          Authorization: String(REACT_APP_TOKEN_REQUEST)
        }
      })
      setVideoFree(data)
    }

    loadVideoFree()
  }, [])

  useEffect(() => {
    async function loadVideoList() {
      const { data } = await api.get(`/lp/videos?per_page=10`, {
        headers: {
          Authorization: String(REACT_APP_TOKEN_REQUEST)
        }
      })
      setVideoList(data)
    }

    loadVideoList()
  }, [])

  return (
    <>
      <Container>
        <div className="top">
          <a
            href="https://dankiclub.com/"
            title={'Danki Club'}
            rel={'noreferrer'}
          >
            <img src={LogoDankiClubWebp} alt="Danki Club" />
          </a>
          <a href={product?.link} className={'signin'} rel={'noreferrer'}>
            Assinar agora por apenas R${product?.price}
          </a>
        </div>
        <Header
          backgroundHeader={BgTechHeaderJPEG}
          backgroundPlayer={BgPlayerWebp}
        >
          <div className="content">
            <article className="sign">
              <h1>
                Conteúdo exclusivo para assinantes que buscam{' '}
                <span>ser acima da média!</span>
              </h1>
              <p>
                Tenha acesso a aulas únicas por menos de uma pizza por mês, como
                uma Netflix. Pronto para começar?
              </p>
              <div>
                <strong>
                  R$ <span>{product?.price}</span>/mês
                </strong>
                <SiginButton
                  href={product?.link ?? ''}
                  title={'Assinar agora'}
                />
              </div>
            </article>

            <div className={'logo-vertical'}>
              <div className="vd-crop">
                <div className="vd-container">
                  <Vimeo responsive video={697729032} color={'d83964'} />
                </div>
              </div>
            </div>
          </div>

          <section className="last-items">
            <HorizontalScrollLastDashboard
              title={'Top 10 mais assistidos'}
              handleRequest={handleGetVideoList}
              items={videoList.data}
              newItemsLimit={30}
              fetchItems={pushNewItemsVideoList}
            />
          </section>
        </Header>

        {videoFree.id && (
          <VideoFree>
            <h2>Aula grátis!</h2>
            <p>
              <FontAwesomeIcon icon={faChevronRight} /> Selecionamos uma de
              nossas aulas em alta dentro do Club, totalmente gratuita para você
              aproveitar, é só dar o play e assistir logo abaixo.
            </p>
            <h3>{videoFree.title}</h3>
            <div className="vd-crop">
              <div className="vd-container">
                <Vimeo
                  responsive
                  video={videoFree.external_id}
                  color={'d83964'}
                />
              </div>
            </div>
            <small
              dangerouslySetInnerHTML={{ __html: videoFree.content }}
            ></small>
          </VideoFree>
        )}

        <PricePlans>
          {products.map((product) => (
            <Plan
              color={product.color}
              backgroundColor={product.backgroundColor}
              key={product.id}
            >
              <header>
                <h1>{product.title ? product.title : product.name}</h1>
              </header>
              <div>
                <strong>{product.price}</strong>
                <p>{product.installments}</p>
              </div>
              {product.granted.map((item) => (
                <p key={item.id}>
                  {item.icon ? (
                    <img src={item.icon} alt={'item.name'} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronCircleRight} />
                  )}{' '}
                  <span className={item.icon && 'destaq'}>{item.name}</span>
                </p>
              ))}
              <a href={product.link} target={'_blank'} rel={'noreferrer'}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Assinar {product.name}
              </a>
            </Plan>
          ))}
        </PricePlans>

        <HighLights>
          <div className="highlights">
            <article>
              <HorizontalScroll
                title={'Top lançamentos'}
                items={highlights.filter((_, index) => index <= 3)}
              />
            </article>
          </div>
        </HighLights>

        <HighLights>
          <div className="high">
            <article>
              <HorizontalScroll
                title={'Populares'}
                items={highlights.filter((_, index) => index > 3)}
                largeCard
              />
            </article>
          </div>
        </HighLights>

        <SignWithText>
          <h2>
            Sempre quis ser acima da média ou aluno da Danki? Junte-se a
            comunidade com mais de 500 mil de bem sucedidos por apenas
          </h2>
          <div>
            <p>
              R${' '}
              <strong>
                <span>{product?.price}</span>/mês
              </strong>
            </p>
            <SiginButton href={product?.link ?? ''} title={'Assinar agora'} />
          </div>
        </SignWithText>
      </Container>

      <ViewAnyWhere>
        <div>
          <img src={BgSectionAnyWhere} alt="" />
        </div>
        <article>
          <header>
            <h1>
              Veja suas novas aulas e conteúdos novos de onde você quiser.
            </h1>
          </header>
          <p>
            Em casa, no transporte, na academia e onde mais você quiser,
            aproveite agora e consuma conteúdos que só assinantes Danki Club têm
            acesso.
          </p>
          <div>
            <a href="/">
              <img src={GooglePlayImg} alt="Google play" />
            </a>
            <a href="/">
              <img src={AppleStore} alt="Apple Store" />
            </a>
          </div>
        </article>
      </ViewAnyWhere>

      <WhyBuy>
        <h2>
          Sabe quando você almoça e assiste uma série?
          <strong>Agora você poderá assinar e aprender sobre…</strong>
        </h2>

        <div className="icons">
          <div>
            <img src={DevSvg} alt="Desenvolvimento Web Front e Back-End" />
            <h3>
              <span>Desenvolvimento</span> Web Front e Back-End
            </h3>
          </div>
          <div>
            <img
              src={MobileSvg}
              alt="Desenvolvimento de Aplicativos Android e IOS"
            />
            <h3>
              <span>Desenvolvimento de Aplicativos</span> Android e IOS
            </h3>
          </div>
          <div>
            <img src={GamesSvg} alt="Desenvolvimento de Games 2D, 3D e Mais" />
            <h3>
              <span>Desenvolvimento</span> de Games 2D, 3D e Mais
            </h3>
          </div>
          <div>
            <img
              src={CryptoSvg}
              alt="Desenvolvimento com Blockchain e CriptoMoedas"
            />
            <h3>
              <span>Desenvolvimento</span> com Blockchain e CriptoMoedas
            </h3>
          </div>
          <div>
            <img
              src={BrainSvg}
              alt="Produtividade, Negociação, Marketing e Muito Mais"
            />
            <h3>
              <span>Produtividade, Negociação,</span> Marketing e Muito Mais
            </h3>
          </div>
        </div>

        <FlixBuy backgroundTv={BgFlixWebp}>
          <img
            src={LogoDankiClubVerticalWebp}
            alt="Assinar Danki Club - Assinatura mensal"
          />
          <article>
            <header>
              <h1>
                Conteúdo único e exclusivo para programadores que buscam ser
                acima da média!
              </h1>
            </header>
            <div>
              <h2>
                R$ <span>{product?.price}</span>/mês
              </h2>
              <SiginButton href={product?.link ?? ''} title={'Assinar agora'} />
            </div>
          </article>
        </FlixBuy>

        <article className="why">
          <header>
            <h1>Por que assinar o Danki Club?</h1>
          </header>
          <ul>
            <li>
              <img
                src={CheckedSvg}
                alt="Conteúdos exclusivos diversos como uma “Netflix Premium da Danki”."
              />
              <p>
                <strong>Conteúdos exclusivos diversos</strong> como uma “Netflix
                Premium da Danki”.
              </p>
            </li>
            <li>
              <img
                src={CheckedSvg}
                alt="Conteúdos de 15 minutos e até 2 horas, pra você ver quando quiser e aprender."
              />
              <p>
                Conteúdos de <strong>15 minutos e até 2 horas</strong>, pra você
                ver quando quiser e aprender.
              </p>
            </li>
            <li>
              <img
                src={CheckedSvg}
                alt="Acesso a toda rede de comunidade Danki."
              />
              <p>
                Acesso a <strong>toda rede de comunidade</strong> Danki.
              </p>
            </li>
            <li>
              <img
                src={CheckedSvg}
                alt="Esta é a forma MAIS BARATA de aprender com a Danki"
              />
              <p>
                Esta é a forma <strong>MAIS BARATA</strong> de aprender com a
                Danki
              </p>
            </li>
            <li>
              <img
                src={CheckedSvg}
                alt="Assine e cancele quando quiser, temos CERTEZA que você não irá se arrepender "
              />
              <p>
                Assine e <strong>cancele quando quiser</strong>, temos CERTEZA
                que você não irá se arrepender
              </p>
            </li>
          </ul>
        </article>

        <div className="faq">
          {faqs.map((item) => (
            <article key={item.id}>
              <img src={FaqSvg} alt={item.title} />
              <h2>{item.title}</h2>
              <p>{item.text}</p>
            </article>
          ))}
        </div>
      </WhyBuy>

      <SiginNow>
        <img
          src={LogoDankiClubVerticalWebp}
          alt="Assinar Danki Club Mensal"
          className={'logo'}
        />
        <header>
          <h1>
            R$ <span>{product?.price}</span>/mês
          </h1>
        </header>
        <SiginButton href={product?.link ?? ''} title={'Assinar agora'} />
      </SiginNow>

      {/*<ModalDefault isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)} link={`${product?.link}`} />*/}
    </>
  )
}
