import React, { ContextType, useEffect, WheelEvent } from 'react'
import { Container } from './styles'
import {
  getItemsPos,
  ScrollMenu,
  slidingWindow,
  VisibilityContext
} from 'react-horizontal-scrolling-menu'
import { Left, Right } from './Arrows'
import { VideoCardLastDashboard } from '../VideoCardLastDashboard'
import { IVideoDataProps } from '../../Interfaces/VideoProps'
import useDrag from '../../Context/useDrag'

interface IHorizontalScrollProps {
  title: string
  fetchItems: () => void
  handleRequest: () => void
  items?: IVideoDataProps[]
  newItemsLimit?: number
  subtitle?: boolean
  largeCard?: boolean
}

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>

export function HorizontalScrollLastDashboard({
  title,
  items = [],
  handleRequest,
  fetchItems,
  newItemsLimit = 20,
  subtitle = false,
  largeCard = false
}: IHorizontalScrollProps) {
  const { dragStart, dragStop, dragMove } = useDrag()

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })

  function onWheel(
    { getItemById, items, visibleItems, scrollToItem }: scrollVisibilityApiType,
    ev: WheelEvent
  ): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15

    if (isThouchpad) {
      ev.stopPropagation()
      return
    }

    if (ev.deltaY < 0) {
      // NOTE: for center items
      const nextGroupItems = slidingWindow(
        items.toItemsKeys(),
        visibleItems
      ).next()
      const { center } = getItemsPos(nextGroupItems)
      scrollToItem(getItemById(center), 'smooth', 'center')
    } else if (ev.deltaY > 0) {
      const prevGroupItems = slidingWindow(
        items.toItemsKeys(),
        visibleItems
      ).prev()
      const { center } = getItemsPos(prevGroupItems)
      scrollToItem(getItemById(center), 'smooth', 'center')
    }
  }

  useEffect(() => {
    handleRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {items && items.length > 0 && (
        <>
          <header>{!subtitle && <h1>{title}</h1>}</header>
          <div>
            {subtitle && <h2>{title}</h2>}
            <ScrollMenu
              LeftArrow={Left}
              RightArrow={
                <Right limit={newItemsLimit} pushNewItems={fetchItems} />
              }
              onWheel={() => onWheel}
              onMouseDown={() => dragStart}
              onMouseUp={({
                  getItemById,
                  scrollToItem,
                  visibleItems
                }: scrollVisibilityApiType) =>
                () => {
                  dragStop()
                  const { center } = getItemsPos(visibleItems)
                  scrollToItem(getItemById(center), 'smooth', 'center')
                }}
              // @ts-ignore
              onMouseMove={handleDrag}
              options={{
                throttle: 0,
                ratio: 0.9,
                rootMargin: '15px',
                threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
              }}
            >
              {items &&
                items.map((item, id) => (
                  <VideoCardLastDashboard
                    large={largeCard}
                    video_id={item.id}
                    itemId={String(id)} // NOTE: itemId is required for track items
                    title={item.title}
                    key={item.id}
                    cover={item.cover_url}
                    cover_origin={item.cover}
                  />
                ))}
            </ScrollMenu>
          </div>
        </>
      )}
    </Container>
  )
}
