import React from 'react'
import { Container } from './styles'

interface ICardImgProps {
  backgroundImg: string
  author: string
  title: string
  classCustom: string
  enabledAt?: number
  cardHeight: number
}

export function CardImg({
  title,
  author,
  backgroundImg,
  classCustom,
  enabledAt,
  cardHeight
}: ICardImgProps) {
  return (
    <Container
      className={classCustom}
      background={backgroundImg}
      cardHeight={cardHeight}
    >
      <div className="gradient">
        <div className={'pre-control'}>
          {enabledAt && <span className="tag">Disponível por {enabledAt}h</span>}
          <h2>{title}</h2>
          <p>
            com <strong>{author}</strong>
          </p>
        </div>
      </div>
    </Container>
  )
}
